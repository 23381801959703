<template>
  <v-container fluid>
    <p class="text-h5 text-left">발명자 관리</p>
    <div class="autho">
      <select v-model="param.searchType" class="yon" style="background-color: white; -moz-appearance: caret; -webkit-appearance: caret">
        <option value="name">발명자명</option>
        <option value="juminNo">주민등록번호</option>
      </select>
      <div class="ml-2 shbtn01">
        <input v-model="param.keyword" type="text" class="code" @keydown.enter="search">
        <a @click="search"></a>
      </div>
    </div>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="6%">
        <col width="14%">
        <col width="10%">
        <col width="15%">
        <col width="10%">
        <col width="10%">
        <col width="15%">
        <col width="30%">
      </colgroup>
      <thead>
      <tr>
        <th class="td_dark_blue">NO.</th>
        <th class="td_dark_blue pointer" @click="setSort('inventorName')">성명 <v-icon v-if="checkSort('inventorName')" small>{{ checkSort('inventorName') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('inventorPart')">소속 <v-icon v-if="checkSort('inventorPart')" small>{{ checkSort('inventorPart') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('juminNo')">주민등록번호 <v-icon v-if="checkSort('juminNo')" small>{{ checkSort('juminNo') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('inventorStat')">상태 <v-icon v-if="checkSort('inventorStat')" small>{{ checkSort('inventorStat') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('inventorPos')">신분구분 <v-icon v-if="checkSort('inventorPos')" small>{{ checkSort('inventorPos') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('inventorTel')">연락처 <v-icon v-if="checkSort('inventorTel')" small>{{ checkSort('inventorTel') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('inventorEmail')">이메일 <v-icon v-if="checkSort('inventorEmail')" small>{{ checkSort('inventorEmail') }}</v-icon></th>
      </tr>
      </thead>
      <thead v-if="loading">
      <tr>
        <th colspan="8" class="td_loading">
          <v-progress-linear
            indeterminate
            color="#0f2585"
          ></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody v-if="items.length > 0">
      <template v-for="(item,index) in items">
        <tr :key="index" :class="selectedIndex === index ? 'selected' : ''">
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.rnum }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.inventorName }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.inventorPart }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.juminNo }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.inventorStat }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.inventorPos }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.inventorTel }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.inventorEmail }}</td>
        </tr>
      </template>
      </tbody>
      <tbody v-else>
      <tr>
        <td class="no-data" colspan="8">데이터를 찾을 수 없습니다.</td>
      </tr>
      </tbody>

    </table>
    <tech-pagination :page.sync="pageable.page" :page-size="10" :total-elements="pageable.totalElements"/>

    <div class="userCont">
      <fieldset>
        <legend>발명자정보</legend>
        <table width="100%" cellspacing="0" cellpadding="4px">
          <colgroup>
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
          </colgroup>
          <tbody>
          <tr>
            <th class="td_blue big">성명</th>
            <td class="td_blue big">주민등록번호</td>
            <td class="td_blue big">소속</td>
            <td class="td_blue big">신분구분</td>
            <td class="td_blue big">상태</td>
          </tr>
          <tr>
            <td class="td_blue"><input v-model="selectedItem.inventorName" type="text" style="width:96%" class="blue_input"></td>
            <td class="td_blue"><input v-model="selectedItem.juminNo" type="text" style="width:96%" class="blue_input"></td>
            <td class="td_blue"><input v-model="selectedItem.inventorPart" type="text" style="width:96%" class="blue_input"></td>
            <td class="td_blue"><input v-model="selectedItem.inventorPos" type="text" style="width:96%" class="blue_input"></td>
            <td class="td_blue"><input v-model="selectedItem.inventorStat" type="text" style="width:96%" class="blue_input"></td>
          </tr>
          <tr>
            <th class="td_blue big">연락처</th>
            <td class="td_blue big">이메일</td>
            <td class="td_blue big">은행</td>
            <td class="td_blue big" colspan="2">계좌번호</td>
          </tr>
          <tr>
            <td class="td_blue"><input v-model="selectedItem.inventorTel" type="text" style="width:96%" class="blue_input"></td>
            <td class="td_blue"><input v-model="selectedItem.inventorEmail" type="text" style="width:96%" class="blue_input"></td>
            <td class="td_blue">
              <select v-model="selectedItem.bankCode" class="select_input03">
                <option :value="null">지역구분를 선택해주세요.</option>
                <option v-for="item in comboItems['4002']" :value="item.codeDetailId" :key="item.codeDetailId">{{ item.codeDetailName }}</option>
              </select>
            </td>
            <td class="td_blue" colspan="2"><input v-model="selectedItem.accountNo" type="text" style="width:96%" class="blue_input"></td>
          </tr>
          <tr>
            <td colspan="5" class="td_blue big">비고</td>
          </tr>
          <tr>
            <td colspan="5" class="td_blue">
              <textarea v-model="selectedItem.remark" cols="100%" rows="2" class="blue_input" style="width:99%"></textarea>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="text-right py-2">
          <v-btn
            color="primary"
            elevation="0"
            @click="saveItem"
          >
            저장
          </v-btn>
        </div>
      </fieldset>
    </div>
  </v-container>
</template>

<script>
import TechPagination from '@/components/Pagination'

export default {
  name: 'InventorManage',
  components: {TechPagination},
  data() {
    return {
      loading: false,
      param: {
        start: 1,
        end: 10,
        searchType: 'name',
        keyword: null,
        sort: ''
      },
      pageable: {
        page: 1,
        totalElements: 0
      },
      items: [],
      comboItems: {},
      selectedIndex: -1,
      selectedItem: {},
    }
  },
  watch: {
    'pageable.page' () {
      this.searchList()
    }
  },
  mounted() {
    this.getCodeDetails()
        .then(this.search)
  },
  methods: {
    search() {
      if (this.pageable.page === 1) {
        this.searchList()
      } else {
        this.pageable.page = 1
      }
    },
    searchList() {
      this.loading = true

      this.param.start = (this.pageable.page - 1) * 10
      this.param.end = this.pageable.page * 10

      this.$http.get('/api/sys/inventor', {params: this.param})
      .then(({ data }) => {
        this.selectedIndex = -1
        this.selectedItem = {}
        this.items = data
        if (data.length > 0) {
          this.pageable.totalElements = data[0].totalElements
        } else {
          this.pageable.totalElements = 0
        }
        this.loading = false
      })
    },
    itemSelect(item, index) {
      this.selectedIndex = index
      // this.selectedItem = item
      this.$http.get(`/api/sys/inventor/${item.juminNo}`)
        .then(({ data }) => {
          this.selectedItem = data
        })
    },
    saveItem() {
      this.$http.post('/api/sys/inventor', this.selectedItem)
          .then(({ data }) => {
            if (data === 'success') {
              alert('저장되었습니다.')
              this.search()
            }
          })
    },
    setSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          this.param.sort = ''
        } else {
          this.param.sort = `${fieldName},desc`
        }
      } else {
        this.param.sort = `${fieldName},asc`
      }
      this.search()
    },
    checkSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          return 'mdi-arrow-down'
        } else {
          return 'mdi-arrow-up'
        }
      } else {
        return ''
      }
    },
    getCodeDetails() {
      this.loading = true
      return this.$http.get('/api/sys/code/detail/all')
          .then(({ data }) => {
            this.comboItems = data
          })
          .finally(() => this.loading = false)
    },
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
